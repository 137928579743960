<template>
  <header class="header" :class="{ 'hidden': !isHeaderVisible }">
    <div id="header">
      <nav class="header-nav">
        <div class="navarea">
          <router-link to="/" class="logo" @click="closeSidenav">
            <img src="@/assets/nomad_logo_black_trans_title.png" alt="ノマドシステム工房 ITコンサルタント・システムエンジニア" width="200" loading="lazy">
          </router-link>
          <div id="main-menu" class="main-menu" :style="{ display: isMainMenuVisible ? '' : 'none' }">
            <Navbar @link-click="closeSidenav" />
          </div>
        </div>
      </nav>
      <div id="hamburger-menu" class="hamburger-menu" :style="{ display: !isMainMenuVisible ? '' : 'none' }">
        <input type="checkbox" id="sidenav-btn-check" v-model="isSidenavOpen">
        <label for="sidenav-btn-check" class="sidenav-btn"><span></span></label>
        <Sidebar @link-click="closeSidenav" />
        <div id="sidenav-overlay" style="opacity: 1;" class="" v-on:click="uncheckSidenavBtn"></div>
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from './nav/Nav.vue'
import Sidebar from './sidenav/Nav.vue'

import { onMounted, onUnmounted, ref, watch } from 'vue';

export default {
  name: 'HeaderItem',
  components: {
    Navbar,
    Sidebar
  },
  setup() {
    const isHeaderVisible = ref(true);
    const isMainMenuVisible = ref(window.innerWidth > 768);
    const isSidenavOpen = ref(false); // サイドナビの状態を管理
    const lastScrollTop = ref(0);
    const scrollTimeout = ref(null);
    const scrollThreshold = 50; // スクロール検出のための閾値

    function uncheckSidenavBtn() {
      isSidenavOpen.value = false;
    }

    function dispHamburgerMenu() {
      isMainMenuVisible.value = window.innerWidth > 768;
    }

    function handleScroll() {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      // スクロール方向を検出
      if (currentScrollTop > lastScrollTop.value + scrollThreshold) {
        // 下方向へのスクロール
        isHeaderVisible.value = false;
        lastScrollTop.value = currentScrollTop;
      } else if (currentScrollTop < lastScrollTop.value - scrollThreshold) {
        // 上方向へのスクロール
        isHeaderVisible.value = true;
        lastScrollTop.value = currentScrollTop;
      }

      // スクロールタイムアウトをリセット
      clearTimeout(scrollTimeout.value);
      scrollTimeout.value = setTimeout(() => {
        // スクロールが止まって0.5秒後に表示
        isHeaderVisible.value = true;
      }, 500);
    }

    // サイドナビの状態を監視
    watch(isSidenavOpen, (newValue) => {
      if (newValue) {
        document.body.classList.add('no-scroll');
        document.getElementsByClassName('header')[0].classList.add('hamburger-menu-open');
      } else {
        document.body.classList.remove('no-scroll');
        document.getElementsByClassName('header')[0].classList.remove('hamburger-menu-open');
      }
    });

    function closeSidenav() {
      isSidenavOpen.value = false;
    }

    onMounted(() => {
      dispHamburgerMenu();
      window.addEventListener('resize', dispHamburgerMenu);
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', dispHamburgerMenu);
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout.value);
    });

    return {
      isHeaderVisible,
      isMainMenuVisible,
      isSidenavOpen,
      uncheckSidenavBtn,
      dispHamburgerMenu,
      closeSidenav
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 768px){
  .logo {
    float: none!important;
    padding-top: 0px;
  }
  
  .navarea {
    width: 100%!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }
  
  .header-nav {
    height: var(--header-height-mobile)!important;
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  transform: translateY(0);
  transition: var(--transition-normal);
}

.hamburger-menu-open {
  height: 100%;
}

.header.hidden {
  transform: translateY(-100%);
}

.header-nav {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 2px 10px var(--color-shadow);
  transition: var(--transition-normal);
  padding: 0.5rem 0;
  height: var(--header-height-desktop);
  display: flex;
  align-items: center;
}

.navarea {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  transition: var(--transition-normal);
}

.logo img {
  transition: transform 0.3s ease;
}

.logo:hover img {
  transform: scale(1.05);
}

.main-menu {
  display: flex;
  align-items: center;
}

.sidenav-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: var(--color-primary);
  border-radius: 50%;
  box-shadow: 0 2px 10px var(--color-shadow);
  transition: var(--transition-normal);
}

.sidenav-btn:hover {
  background-color: var(--color-primary-dark);
  transform: scale(1.05);
}

.sidenav-btn span,
.sidenav-btn span:before,
.sidenav-btn span:after {
  content: '';
  display: block;
  height: 2px;
  width: 20px;
  border-radius: 3px;
  background-color: var(--color-text-light);
  position: absolute;
  transition: var(--transition-normal);
}

.sidenav-btn span:before {
  bottom: 6px;
}

.sidenav-btn span:after {
  top: 6px;
}

#sidenav-btn-check:checked ~ .sidenav-btn span {
  background-color: rgba(255, 255, 255, 0);
}
#sidenav-btn-check:checked ~ .sidenav-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
  transition: var(--transition-normal);
}
#sidenav-btn-check:checked ~ .sidenav-btn span::after {
  top: 0;
  transform: rotate(-45deg);
  transition: var(--transition-normal);
}

#sidenav-btn-check {
  display: none;
}

#sidenav-btn-check:checked ~ .sidebar {
  right: 0;
  box-shadow: -5px 0 15px var(--color-shadow);
}

#sidenav-btn-check:checked ~ #sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0,0,0,0.6);
  z-index: 75;
  will-change: opacity;
  transition: var(--transition-normal);
  backdrop-filter: blur(2px);
}
</style>
