import { createRouter, createWebHashHistory } from 'vue-router'
import TopView from '../views/Top.vue'

const routes = [
  {
    path: '/',
    name: 'TopView',
    component: TopView,
    meta: {
      title: 'ITコンサルタント・システムエンジニア',
      desc: '東京都町田市を拠点に活動するITコンサルタント・システムエンジニア。システム開発、業務改善、Webサイト構築、運用保守など10年以上の実績で貴社の課題を解決します。',
      keyword: '東京,東京都,町田,町田市,ITコンサルタント,システムエンジニア,システム開発,WEBサイト,運用,保守,システムコンサルティング,システム導入,業務改善',
      og_locale: "ja_JP",
      og_site_name: "ノマドシステム工房",
      og_type: "website",
      og_title: "ITコンサルタント・システムエンジニア",
      og_url: "https://www.nomadsystem.works/",
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'プロフィール・実績',
      desc: '東京都町田市を拠点に活動するITコンサルタント・システムエンジニアのプロフィールと実績。10年以上の経験を活かし、様々な業界のシステム開発に携わってきました。',
      keyword: '東京,東京都,町田,町田市,ITコンサルタント,システムエンジニア,プロフィール,実績,スキル,経験',
      og_locale: "ja_JP",
      og_site_name: "ノマドシステム工房",
      og_type: "website",
      og_title: "プロフィール・実績",
    }
  },
  {
    path: '/blog',
    name: 'BlogView',
    beforeEnter() {
      window.location = "https://blog.nomadsystem.works/"
    },
    meta: {
      title: 'ブログ - IT技術情報', 
      desc: '東京都町田市を拠点に活動するITコンサルタント・システムエンジニアのブログ。システム開発、業務改善、最新技術トレンドなどの情報を発信しています。',
      keyword: '東京,東京都,町田,町田市,ITコンサルタント,システムエンジニア,ブログ,技術情報,システム開発,業務改善',
      og_locale: "ja_JP",
      og_site_name: "ノマドシステム工房",
      og_type: "website",
      og_title: "ブログ - IT技術情報",
    }
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: 'お問い合わせ',
      desc: '東京都町田市を拠点に活動するITコンサルタント・システムエンジニアへのお問い合わせ。システム開発、業務改善、Webサイト構築などについてのご相談を承っております。',
      keyword: '東京,東京都,町田,町田市,ITコンサルタント,システムエンジニア,お問い合わせ,相談,見積もり',
      og_locale: "ja_JP",
      og_site_name: "ノマドシステム工房",
      og_type: "website",
      og_title: "お問い合わせ",
    }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicyView',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      title: 'プライバシーポリシー',
      desc: 'ノマドシステム工房のプライバシーポリシー。お客様の個人情報の取り扱いについて定めています。',
      keyword: 'プライバシーポリシー,個人情報保護方針,ノマドシステム工房',
      og_locale: "ja_JP",
      og_site_name: "ノマドシステム工房",
      og_type: "website",
      og_title: "プライバシーポリシー",
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundView',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: '404 Not Found',
      desc: 'ページが見つかりません',
      keyword: '404,Not Found',
      og_locale: "ja_JP",
      og_site_name: "404 Not Found",
      og_type: "website",
      og_title: "404 Not Found",
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // スムーズにページトップにスクロール
    return {
      top: 0,
      behavior: 'smooth'
    }
  }
})

export default router
