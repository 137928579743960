<template>
  <div class="sidebar">
    <ul v-on:click="uncheckSidenavBtn">
      <li><a href="#" @click.prevent="handleLinkClick('/')">ホーム</a></li>
      <li><a href="#" @click.prevent="handleLinkClick('/about')">概要</a></li>
      <!--<li><router-link to="/service">サービス</router-link></li>-->
      <li><a href="#" @click.prevent="handleLinkClick('/contact')">お問い合わせ</a></li>
      <li><a href="#" @click.prevent="handleLinkClick('/privacy')">プライバシーポリシー</a></li>
      <li><a href="https://blog.nomadsystem.works/" target="_blank" rel="noopener noreferrer">ブログ</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideNavItem',
  setup() {
    function uncheckSidenavBtn() {
      var checkbox = document.getElementById('sidenav-btn-check');
      checkbox.checked = false;
    }

    return {
      uncheckSidenavBtn
    }
  },
  methods: {
    handleLinkClick(path) {
      this.$emit('link-click'); // link-clickイベントを発火
      this.$router.push(path);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar {
    width: 300px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 80;
    background-color: #2d3748;
    transition: all 0.3s;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.sidebar ul {
    padding: 80px 0 0;
    margin: 0;
}

.sidebar ul li {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    list-style: none;
    margin: 0;
}

.sidebar ul li a {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    color: #f7fafc;
    text-decoration: none;
    padding: 15px 20px;
    position: relative;
    transition: all 0.3s ease;
}

.sidebar ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.sidebar ul li a.router-link-active {
    background-color: rgba(49, 130, 206, 0.7);
    color: white;
    font-weight: 500;
}

.sidebar ul li a::after {
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    right: 20px;
    font-size: 12px;
    opacity: 0.8;
}

/* 外部リンクのスタイル */
.sidebar ul li a[target="_blank"]::after {
    content: "\f08e";  /* 外部リンクアイコン */
}

.sidebar ul li a[target="_blank"]:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>
