<template>
  <ul class="navbar">
    <li><a href="#" @click.prevent="navigateTo('/')">ホーム</a></li>
    <li><a href="#" @click.prevent="navigateTo('/about')">概要</a></li>
    <!--<li><router-link to="/service">サービス</router-link></li>-->
    <li><a href="#" @click.prevent="navigateTo('/contact')">お問い合わせ</a></li>
    <li><a href="#" @click.prevent="navigateTo('/privacy')">プライバシーポリシー</a></li>
    <li><a href="https://blog.nomadsystem.works/" target="_blank" rel="noopener noreferrer">ブログ</a></li>
  </ul>
</template>

<script>
export default {
  name: 'NavItem',
  methods: {
    navigateTo(path) {
      // まず上部にスクロール
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
      // スクロール完了後に遷移（スクロールアニメーションの時間を考慮）
      setTimeout(() => {
        this.$router.push(path);
      }, 500); // スクロールが完了するまでの概算時間
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin: 0 15px;
}

.navbar li a {
  color: #2d3748;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 0;
  position: relative;
  transition: all 0.3s ease;
}

.navbar li a:hover {
  color: #3182ce;
}

.navbar li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3182ce;
  transition: width 0.3s ease;
}

.navbar li a:hover::after,
.navbar li a.router-link-active::after {
  width: 100%;
}

.navbar li a.router-link-active {
  color: #3182ce;
  font-weight: 600;
}

/* 外部リンクのスタイル */
.navbar li a[target="_blank"]::after {
  bottom: -2px;
}

.navbar li a[target="_blank"]:hover::after {
  width: 100%;
}
</style>
