<template>
  <div class="content top">
    <div class="hero-section">
      <div class="hero-overlay"></div>
      <div class="hero-content">
        <h1 class="hero-title">ITをもっと身近に</h1>
        <p class="hero-subtitle">システム開発・WEB構築・IT導入支援</p>
        <p class="hero-description">お困りごとあればご相談ください</p>
        <router-link to="/contact" class="hero-button">お問い合わせ</router-link>
      </div>
    </div>

    <div class="section services-section">
      <div class="section-container">
        <h2 class="section-title">事業内容</h2>
        <div class="services-grid">
          <div class="service-card">
            <div class="service-icon">
              <i class="fas fa-chart-line"></i>
            </div>
            <h3 class="service-title">業務分析・改善</h3>
            <p class="service-description">会社の現状を分析し、AsIs-ToBeを表現しシステム化の支援を行います。</p>
          </div>
          
          <div class="service-card">
            <div class="service-icon">
              <i class="fas fa-laptop-code"></i>
            </div>
            <h3 class="service-title">システム開発</h3>
            <p class="service-description">AWS等のサービスを使用してインフラ構築からの一貫したWEBシステム開発や業務に必要なツールを製作し業務の支援を行います。</p>
          </div>
          
          <div class="service-card">
            <div class="service-icon">
              <i class="fas fa-globe"></i>
            </div>
            <h3 class="service-title">WEBサイト構築</h3>
            <p class="service-description">WordPressの構築やコーポレートサイトの構築を行います。</p>
          </div>
          
          <div class="service-card">
            <div class="service-icon">
              <i class="fas fa-cogs"></i>
            </div>
            <h3 class="service-title">運用保守</h3>
            <p class="service-description">既存システムのメンテやシステム改修を行い業務の支援を行います。</p>
          </div>
        </div>
        <div class="services-cta">
          <p>詳細は<router-link to="/contact">お問い合わせ</router-link>ください。</p>
        </div>
      </div>
    </div>

    <div class="section strengths-section">
      <div class="section-container">
        <h2 class="section-title section-title-strong">ノマドシステム工房の強み</h2>
        <div class="strengths-grid">
          <div class="strength-card">
            <div class="strength-number">01</div>
            <h3>高品質な開発</h3>
            <p>豊富な経験と最新技術で、高品質なシステムを提供します。</p>
          </div>
          <div class="strength-card">
            <div class="strength-number">02</div>
            <h3>柔軟な対応</h3>
            <p>お客様のニーズに合わせて柔軟にカスタマイズします。</p>
          </div>
          <div class="strength-card">
            <div class="strength-number">03</div>
            <h3>サポート体制</h3>
            <p>開発後も安心のサポート体制を整えています。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jquery from 'jquery'
//import axios from 'axios'

export default {
  name: 'TopItem',
  mounted: function(){
    jquery('.repeat').click(function(){
        var classes =  jquery(this).parent().attr('class');
        jquery(this).parent().attr('class', 'animate');
        var indicator = jquery(this);
        setTimeout(function(){ 
          jquery(indicator).parent().addClass(classes);
        }, 20);
      });
    
    /*
    axios
      .get('https://blog.nomadsystem.works/wp-json/wp/v2/posts?per_page=4&_embed')
      .then((response) => {
        for(var i = 0; i < response.data.length; i++) {
          var divImg = jquery('<div></div>').addClass('blog-card-img');
          var img = jquery('<img></img>').attr('src', response.data[i]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['thumbnail'].source_url).attr('alt', 'logo');
          divImg.append(img);

          var divTitle = jquery('<div></div>').addClass('blog-card-title');
          var title = response.data[i].title.rendered;
          divTitle.text(title);

          var a = jquery('<a></a>').attr('href', response.data[i].link);
          a.append(divImg).append(divTitle);

          var divContainer = jquery('<div></div>').addClass('blog-card-container' + (i + 1)).append(a);

          jquery('#blog-area').append(divContainer);
        }
      });
    */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  overflow-x: hidden;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 85vh;
  min-height: 600px;
  background: url(@/assets/top_eyecatch.jpeg) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  margin-top: -80px; /* ヘッダーのパディング分を相殺 */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 0 2rem;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  letter-spacing: 2px;
  animation: fadeInUp 1s ease;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.9;
  animation: fadeInUp 1s ease 0.2s forwards;
  opacity: 0;
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.8;
  animation: fadeInUp 1s ease 0.4s forwards;
  opacity: 0;
}

.hero-button {
  display: inline-block;
  background: #3182ce;
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border-radius: 30px;
  transition: all 0.3s ease;
  animation: fadeInUp 1s ease 0.6s forwards;
  opacity: 0;
  text-decoration: none;
}

.hero-button:hover {
  background: #2c5282;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-title-strong {
  color: var(--color-text-light);
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.service-card {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-icon {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  color: var(--color-primary);
}

.service-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--color-text-main);
}

.service-description {
  color: var(--color-text-muted);
  line-height: 1.6;
}

.services-cta {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.1rem;
}

.services-cta a {
  color: var(--color-primary);
  text-decoration: underline;
  transition: color 0.3s ease;
}

.services-cta a:hover {
  color: var(--color-primary-dark);
}

/* Strengths Section */
.strengths-section {
  background-color: var(--color-text-main);
  color: white;
}

.strengths-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.strength-card {
  padding: 2rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
}

.strength-card:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-5px);
}

.strength-number {
  position: absolute;
  top: -20px;
  left: -10px;
  font-size: 5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.1);
  line-height: 1;
}

.strength-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  color: white;
}

.strength-card p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

/* Responsive */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
  }
  
  .hero-description {
    font-size: 1rem;
  }
  
  .strength-number {
    font-size: 3.5rem;
  }
  
  .hero-section {
    margin-top: -60px; /* モバイル表示時のマージン調整 */
  }
}
</style>
