<template>
  <div id="footer" class="footer">
    <div class="footer-content">
      <div class="footer-logo">
        <img src="@/assets/nomad_logo_black_trans_title.png" alt="ノマドシステム工房 ITコンサルタント・システムエンジニア" width="150" loading="lazy">
      </div>
      <div class="footer-links">
        <a href="#" @click.prevent="navigateTo('/')">ホーム</a>
        <a href="#" @click.prevent="navigateTo('/about')">概要</a>
        <a href="#" @click.prevent="navigateTo('/contact')">お問い合わせ</a>
        <a href="#" @click.prevent="navigateTo('/privacy')">プライバシーポリシー</a>
        <a href="https://blog.nomadsystem.works/" target="_blank" rel="noopener noreferrer">ブログ</a>
      </div>
      <div class="footer-copyright">
        © {{currentYear}} NomadSystemWorks. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterItem',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    navigateTo(path) {
      // まず上部にスクロール
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
      // スクロール完了後に遷移（スクロールアニメーションの時間を考慮）
      setTimeout(() => {
        this.$router.push(path);
      }, 500); // スクロールが完了するまでの概算時間
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background: var(--color-dark);
  color: var(--color-text-light);
  width: 100%;
  padding: 3rem 0 1.5rem;
  margin-top: 0;
}

.footer-content {
  width: 90%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.footer-logo {
  margin-bottom: 1rem;
}

.footer-logo img {
  filter: brightness(0) invert(1);
  opacity: 0.9;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer-links a {
  color: var(--color-text-muted);
  font-size: 0.9rem;
  transition: var(--transition-normal);
}

.footer-links a:hover {
  color: var(--color-text-light);
}

.footer-copyright {
  font-size: 0.8rem;
  color: var(--color-text-muted);
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 0 1rem;
  }
  
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
</style>
