<template>
  <div class='container'>
    <Header />

    <router-view v-slot="{ Component }">
      <transition name="bounce" mode="out-in">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>

    <Footer />
  </div>
</template>

<script>
import Header from './views/common/Header.vue'
import Footer from './views/common/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  mounted : function(){
      var routeInstance = this.$route;
      this.createTitleDesc(routeInstance);
      
      // ルーター遷移後のイベントを監視
      this.$router.afterEach((to) => {
        // ハッシュがある場合は、該当要素までスクロール
        if (to.hash) {
          setTimeout(() => {
            const element = document.getElementById(to.hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 500);
        }
      });
  },
  methods : {
    createTitleDesc : function(routeInstance){
      //titleを設定する処理
      if (routeInstance.meta.title) {
          var setTitle = routeInstance.meta.title + ' | ノマドシステム工房';
          document.title = setTitle;
      } else {
          document.title = 'ノマドシステム工房 | ITコンサルタント・システムエンジニア';
      }

      // メタタグのdescription設定処理
      if (routeInstance.meta.desc) {
          var setDesc = routeInstance.meta.desc + ' | ノマドシステム工房';
          document.querySelector("meta[name='description']").setAttribute('content', setDesc);
      } else {
          document.querySelector("meta[name='description']").setAttribute('content', '');
      }

      // メタタグのkeyword設定処理
      if (routeInstance.meta.keyword) {
          var setKeyword = routeInstance.meta.keyword;
          document.querySelector("meta[name='keywords']").setAttribute('content', setKeyword);
      } else {
          document.querySelector("meta[name='keywords']").setAttribute('content', '');
      }

      // メタタグのog:locale設定処理
      if (routeInstance.meta.og_locale) {
          var setOgLocale = routeInstance.meta.og_locale;
          document.querySelector("meta[property='og:locale']").setAttribute('content', setOgLocale);
      } else {
          document.querySelector("meta[property='og:locale']").setAttribute('content', '');
      }

      // メタタグのog:site_name設定処理
      if (routeInstance.meta.og_site_name) {
          var setOgSiteName = routeInstance.meta.og_site_name + ' | ノマドシステム工房';
          document.querySelector("meta[property='og:site_name']").setAttribute('content', setOgSiteName);
      } else {
          document.querySelector("meta[property='og:site_name']").setAttribute('content', '');
      }

      // メタタグのog:type設定処理
      if (routeInstance.meta.og_type) {
          var setOgType = routeInstance.meta.og_type;
          document.querySelector("meta[property='og:type']").setAttribute('content', setOgType);
      } else {
          document.querySelector("meta[property='og:type']").setAttribute('content', '');
      }

      // メタタグのog:title設定処理
      if (routeInstance.meta.og_title) {
          var setOgTitle = routeInstance.meta.og_title + ' | ノマドシステム工房';
          document.querySelector("meta[property='og:title']").setAttribute('content', setOgTitle);
      } else {
          document.querySelector("meta[property='og:title']").setAttribute('content', '');
      }

      // メタタグのkeyword設定処理
      if (routeInstance.meta.og_url) {
          var setOgUrl = routeInstance.meta.og_url;
          document.querySelector("meta[property='og:url']").setAttribute('content', setOgUrl);
      } else {
          document.querySelector("meta[property='og:url']").setAttribute('content', '');
      }
    }
  },
  watch: { 
    '$route' (routeInstance) {
        this.createTitleDesc(routeInstance);
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  /* カラー変数 */
  --color-primary: #3182ce;
  --color-primary-dark: #2c5282;
  --color-primary-light: #63b3ed;
  --color-text-main: #2d3748;
  --color-text-dark: #1a202c;
  --color-text-light: #f7fafc;
  --color-text-muted: #a0aec0;
  --color-background: #f8f9fa;
  --color-dark: #2c5282;
  --color-border: #ddd;
  --color-shadow: rgba(0, 0, 0, 0.1);
  
  /* サイズ変数 */
  --header-height-desktop: 70px;
  --header-height-mobile: 55px;
  --content-padding-desktop: 80px;
  --content-padding-mobile: 60px;
  
  /* ブレークポイント */
  --breakpoint-tablet: 992px;
  --breakpoint-mobile: 768px;
  
  /* その他 */
  --transition-normal: all 0.3s ease;
  --box-shadow-normal: 0 4px 6px rgba(0, 0, 0, 0.05);
  --box-shadow-hover: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  50% {
    transform: scale(1.02);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

body {
  background-color: var(--color-background);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Noto Sans JP', sans-serif;
  color: #333;
  line-height: 1.6;
}

#app {
  font-family: 'Noto Sans JP', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-text-main);
  font-size: 16px;
  margin: 0;
}

.container {
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
        "header"
        "content"
        "footer";
  min-height: 100vh;
}

.header {
  grid-area: header;
  width: 100%;
  margin: 0 auto;
}

.content {
  grid-area: content;
  width: 100%;
  margin: 0 auto;
  padding: 5rem 0;
}

.footer {
  grid-area: footer;
  width: 100%;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', 'Noto Sans JP', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--color-text-dark);
}

h3 {
  margin: 40px 0 20px;
  font-size: 1.8rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 0 10px;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: var(--transition-normal);
}

a:hover {
  color: var(--color-primary-dark);
}

.btn {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: var(--color-primary);
  color: white;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition-normal);
}

.btn:hover {
  background-color: var(--color-primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-normal);
}

.card {
  background: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow-normal);
  padding: 1.5rem;
  transition: var(--transition-normal);
}

.card:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-5px);
}

.section {
  padding: 5rem 0;
  background-color: var(--color-background);
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3.5rem;
  position: relative;
  color: #2c3e50;
  font-weight: 700;
}

.section-title:after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, var(--color-primary), var(--color-primary-light));
  border-radius: 2px;
}

.content-inner-wrapper {
  line-height: 1.8;
  color: var(--color-text-main);
}

.content-inner-wrapper h3 {
  margin-top: 70px;
  margin-bottom: 30px;
  font-size: 1.8rem;
  color: var(--color-text-main);
  position: relative;
  padding-left: 15px;
  border-left: 4px solid var(--color-primary);
}

.content-inner-wrapper p {
  text-align: left;
  line-height: 1.8em;
  margin-bottom: 1.8rem;
  font-size: 1.05rem;
}

.no-scroll {
  overflow: hidden;
}

/* スマホ向けのスタイル */
@media (max-width: 768px) {
  .content {
    padding-top: var(--content-padding-mobile);
  }

  .section-title {
    font-size: 2rem;
  }
}
</style>
